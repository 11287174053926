<template>
  <div class="panel">
    <div class="panel-block">
      <ul class="labelList" v-if="isCompanyAccount">
        <li class="labelList-item">
          <div class="labelList-item-label">單位全銜：</div>
          <div class="labelList-item-content">{{ userData.unitName }}</div>
        </li>
        <li class="labelList-item">
          <div class="labelList-item-label">統一編號：</div>
          <div class="labelList-item-content">{{ userData.unNumber }}</div>
        </li>
        <li class="labelList-item">
          <div class="labelList-item-label">負責人姓名：</div>
          <div class="labelList-item-content">{{ userData.headerName }}</div>
        </li>
      </ul>
      <ul class="labelList" v-else>
        <li class="labelList-item">
          <div class="labelList-item-label">姓名：</div>
          <div class="labelList-item-content">{{ userData.name }}</div>
        </li>
        <li class="labelList-item">
          <div class="labelList-item-label">身分證字號：</div>
          <div class="labelList-item-content">{{ userData.iDNumber }}</div>
        </li>
      </ul>
    </div>
    <div class="panel-block">
      <div class="panel-block-title">聯絡方式</div>
      <div class="grid-row">
        <div class="col-12_md-6">
          <div v-if="isCompanyAccount">主要聯絡人</div>
          <ul class="labelList">
            <li class="labelList-item" v-if="isCompanyAccount">
              <div class="labelList-item-label">姓名：</div>
              <div class="labelList-item-content">{{ userData.contactName }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">Email：</div>
              <div class="labelList-item-content">{{ userData.contactEmail }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">連絡電話：</div>
              <div class="labelList-item-content">{{ userData.contactTel }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">手機號碼：</div>
              <div class="labelList-item-content">{{ userData.contactMobile }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">傳真：</div>
              <div class="labelList-item-content">{{ userData.contactFax || "-" }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">聯絡地址：</div>
              <div class="labelList-item-content">
                {{ userData.address }}
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12_md-6">
          <div>其他聯絡人</div>
          <ul class="labelList">
            <li class="labelList-item">
              <div class="labelList-item-label">姓名：</div>
              <div class="labelList-item-content">{{ userData.otherName }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">Email：</div>
              <div class="labelList-item-content">{{ userData.otherEmail }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">連絡電話：</div>
              <div class="labelList-item-content">{{ userData.otherTel }}</div>
            </li>
            <li class="labelList-item">
              <div class="labelList-item-label">手機號碼：</div>
              <div class="labelList-item-content">{{ userData.otherMobile }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-group">
    <RouterLink to="/user/edit" class="btn btn-primary btn-md">會員資料修改</RouterLink>
    <RouterLink to="/user/password" class="btn btn-primary btn-md">密碼管理</RouterLink>
  </div>
</template>
<script>
import { mapState } from 'pinia';
import { useUserData } from '@/store/user';

export default {
  computed: {
    ...mapState(useUserData, ['userData', 'userName', 'isCompanyAccount']),
  },
};
</script>
